export default () => {
    return(
        <>
        <section id="location" className="about-area pt-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <div className="section-title">
                                <div className="line"></div>
                                <h3 className="title">Stutong Branch Kuching</h3>
                            </div>
                            <p className="text"><b>Address: </b>Ground Floor 16, precinct 2 Jalan Setia Raja, 93350 Kuching, Sarawak, Malaysia (Opposit OneTJ)</p>
                            <p className="text"><b>Business Hour:</b> </p>
                            <div className="row">
                                <p className="col-3 text-right">
                                    <br /> <b>Mon - Sat:</b>
                                    <br /> <b>Sunday:</b>
                                </p>
                                <p className="col-6">
                                    <br /> 8am - 8pm
                                    <br /> 9am - 5.30pm
                                </p>
                            </div>
                            
                            <a href="https://goo.gl/maps/TcdWF9eKMa9gMX6VA" target="_blank"  style={{color:"#fff"}} className="wellclean-btn">Google Map</a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <img src="assets/images/stutong.jpg" alt="WellClean Stutong Branch" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-shape-1">
                <img src="assets/images/about-shape-1.svg" alt="shape" />
            </div>
        </section>
        
        <section className="about-area pt-70">
        <div className="about-shape-2">
            <img src="assets/images/about-shape-2.svg" alt="shape" />
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div className="section-title">
                            <div className="line"></div>
                            <h3 className="title">Satok Branch Kuching</h3>
                            <p className="text"><b>Address:</b>Ground Floor, Lot 335, Rubber Road, 93300 Kuching, Sarawak, Malaysia</p>
                            <p className="text"><b>Business Hour:</b> </p>
                        </div>
                            <div className="row col-12">
                                <p className="col-3 text-right">
                                    <br /> <b>Mon - Sat:</b>
                                    <br /> <b>Sunday:</b>
                                </p>
                                <p className="col-9">
                                    <br /> 8am - 5:30pm
                                    <br /> Close
                                </p>
                            </div>
                        <a href="https://goo.gl/maps/mkNUdPK2LZ1ptfYW8" target="_blank" style={{color:"#fff"}} className="wellclean-btn">Google Map</a>
                    </div>
                </div>
                <div className="col-lg-6 order-lg-first">
                    <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                        <img src="assets/images/satok.jpg" alt="WellClean Satok Branch" />
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="location" className="about-area pt-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                    <div className="about-content mt-50 wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div className="section-title">
                            <div className="line"></div>
                            <h3 className="title">BDC Branch Kuching</h3>
                            <p className="text"><b>Address:</b>Lot 7998 Ground Floor , Sublot 17 Jln Tun Jugah Chayi Goldland, 93350 Kuching, Sarawak MY</p>
                            <p className="text"><b>Business Hour:</b> </p>
                        </div>
                        <div className="row">
                            <p className="col-3 text-right">
                                <br /> <b>Mon - Sat:</b>
                                <br /> <b>Sunday:</b>
                            </p>
                            <p className="col-6">
                                <br /> 8am - 9pm
                                <br /> 8am - 6.30pm
                            </p>
                        </div>
                        <a href="https://g.page/r/CdSXqR2LPomdEBA" target="_blank" style={{color:"#fff"}} className="wellclean-btn">Google Map</a>
                    </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-image text-center mt-50 wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                            <img src="assets/images/chayi.jpg" alt="WellClean Stutong Branch" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-shape-1">
                <img src="assets/images/about-shape-1.svg" alt="shape" />
            </div>
        </section>
        </>
    )
}