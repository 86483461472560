export default () => {
    let price_list_one = {
        normal_wash : {name:"ColdNormal Wash", price: 6.00, },
        normal_wash_warm : {name:"Warm Water Normal Wash", price: 6.30, after: "Removes dirt and light stains better than cold water, helps detergent work effectively, and is gentle on fabrics"},
        normal_wash_hot : {name:"Hot Water Normal Wash", price: 6.60, after:"Removes tough stains, grease, oil, and heavy dirt. Kills bacteria, dust mites, and allergens effectively, keeping garments hygienic" },
        hand_wash : {name:"Gental Wash(Hand Wash)", price: 10.80},
        dry_only : {name:"Dry Only", price: 10.80},
    }
    let price_list_hosehold = [
        {name:"Comforter Single/Queen", price: 21.00, unit: "Pcs"},
        {name:"Comforter King", price: 24.00, unit: "Pcs"},
        {name:"Pillow / Bolster Case", price: 2.00, unit: "Pcs"},
        {name:"Quilts / Bed Spread", price: 17.00, unit: "Pcs"},
        {name:"Quilts Cover / Bed Sheet", price: 12.00, unit: "Pcs"},
        {name:"Bedding Set", price: 49.00, unit: "Pcs", after:"(1 x Comforter + 1 Bedsheets + 1 x Bedspread + 5 Pillow Case)"},
        {name:"Curtain", price: 13.80, unit: "Pcs"},
        {name:"Curtain thin", price: 15.00, unit: "Pcs"},
        {name:"Blanket / Cushion Cover / Table Cloth", price: 11.00, unit: "Pcs"},
        {name:"floor Mat / Prayer Mat", price: 4.00, unit: "sqft" },
        {name:"Carpet (thinkness within 2 cm)", price: 4.50, unit: "sqft"},
        {name:"Carpet (thinkness within 4 cm)", price: 5.00, unit: "sqft"},
        {name:"Cap", price: 12.00, unit: "Pcs"},
        {name:"Bag",  before:"from", price: 29.00, unit: "Pcs"},
        {name:"Shoes",  before:"from", price: 35.00, unit: "Pcs"},
        {name:"Baby Car Seat / Stroller",  before:"from", price: 59.00, unit: "Pcs"},
        {name:"Soft Toy",  before:"from",  price: 10.00, unit: "Pcs"}
    ]

    let price_list_dry_cleaning = [
        {name:"Blouse / Shirt / T-Shirt / Trousers", price: 15.00, unit: "Pcs"},
        {name:"Sampin / Scarf", price: 14.00, unit: "Pcs"},
        {name:"Top Baju Kurong / Sweater / Skirt", price    : 16.00, unit: "Pcs"},
        {name:"Man Suit", price: 29.00, unit: "Set"},
        {name:"Baju Kurong", price: 30.00, unit: "Set"},
        {name:"Baju Melayu", price: 30.00, unit: "Set"},
        {name:"Coat", price: 18.00, unit: "Pcs"},
        {name:"Jacket", price: 22.00, unit: "Pcs"},
        {name:"Dress", price: 19.00, unit: "Pcs"},
        {name:"Long Dress / Chongsam", price: 25.00, unit: "Pcs"},
        {name:"Robe / Overcoat", price: 30.00, unit: "Pcs"},
        {name:"Tie", price: 11.00, unit: "Pcs"},
        {name:"Vest", price: 14.00, unit: "Pcs"},
        {name:"Bed Sheet", price: 28.00, unit: "Pcs"},
        {name:"Bed Spread", price: 49.00, unit: "Pcs"},
        {name:"Comforter Single / Queen", price: 58.00, unit: "Pcs"},
        {name:"Comforter King", price: 68.00, unit: "Pcs"},
        {name:"Blanket/ Cushion Cover / Table Cloth", price: 19.00, unit: "Kg"},
        {name:"Curtain", price: 26.00, unit: "Kg"},
        {name:"Hand Bag",  before:"from", price: 55.00, unit: "Pcs"},
        {name:"Evening Dress", before:"from", price: 40.00, unit: "Pcs"},
        {name:"Wedding Dress", before:"from", price: 79.00, unit: "Pcs"}

    ]
    let price_list_ironing = [
        {name:"Shirt / T-Shirt / Trousers", price: 5.80, unit: "Pcs"},
        {name:"Coat / Dress", price: 7.80, unit: "Pcs"},
        {name:"Long Dress / Chongsam", price: 9.80, unit: "Pcs"}
    ]


    return(
        <section id="prices" className="about-area pt-70">
        <div className="container">

        <div className="line m-auto"></div>
        <div className="row justify-content-center">
            <div className="section-title">
                <div className="line"></div>
                <h3 className="title">Laundry Pricing</h3>
            </div>
            <div className="col-md-6">
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                    <h5><b>Wash + Dry + Fold</b></h5>
                    <br />
                    <ul className="leaders">
                        <li>
                            <span><b>{price_list_one.normal_wash.name}</b></span>
                            <span>RM {price_list_one.normal_wash.price.toFixed(2)} / KG</span>
                        </li>
                    </ul>
                    <small><i>Washed with industry washer added premium detergent & softener. Perfect for your everyday laundry needs, including shirts, pants, towels, bedding, and more.</i></small>
                    <br /><br />
                    <small><i>Washed with industry washer added premium detergent & softener. Perfect for your everyday laundry needs, including shirts, pants, towels, bedding, and more.</i></small>
                    <br /><br />
                    <ul className="leaders">
                        <li>
                            <span><b>{price_list_one.normal_wash_warm.name}</b></span>
                            <span>RM {price_list_one.normal_wash_warm.price.toFixed(2)} / KG</span>
                        </li>
                    </ul>    
                    <small><i>{price_list_one.normal_wash_warm.after}</i></small>
                    <br /><br />

                    <ul className="leaders">
                        <li>
                            <span><b>{price_list_one.normal_wash_hot.name}</b></span>
                            <span>RM {price_list_one.normal_wash_hot.price.toFixed(2)} / KG</span>
                        </li>
                    </ul>    
                    <small><i>{price_list_one.normal_wash_hot.after}</i></small>
                    <br /><br />

                    <ul className="leaders">
                        <li>
                            <span><b>{price_list_one.hand_wash.name}</b></span>
                            <span>RM {price_list_one.hand_wash.price.toFixed(2)} / KG</span>
                        </li>
                    </ul>    
                    <small><i>Cleaned by hand with premium detergent and softener, spin in extractor in low G-force (much more gentle compare to wings dry by hand). Perfect for delicate items, such as sportswear, undergarments, and other items that could easily damage or shrink in high heat drying process.</i></small>

                </div>
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                    <h5><b>Dry + Fold</b></h5>
                    <br />
                    <ul className="leaders">
                        <li>
                            <span><b>{price_list_one.dry_only.name}</b></span>
                            <span>RM {price_list_one.dry_only.price.toFixed(2)} / KG</span>
                        </li>
                        <li>
                            <span><b>Household Item</b></span>
                            <span>70% of household item price</span>
                        </li>
                    </ul>    
                    <small><i>Spin in extractor, tumble dry later </i></small>
                </div>
                
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                <h5><b>Household / Special Item </b></h5>
                <br />
                <ul className="leaders">                   
                    {price_list_hosehold.map((item, index) => {
                            return(
                                <li key={index}>
                                    <span>{item.name}</span>
                                    <span>{item.before ? item.before : ''} RM {item.price} / {item.unit} </span><br />
                                    <small>{item.after ? item.after : ''}</small>
                                </li>
                            )
                        })} 
                </ul>

                </div>
            </div> 
            <div className="col-md-6">
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                     
                    <h5><b>Dry Cleaning</b></h5>
                    <br />
                    <ul className="leaders">
                        {price_list_dry_cleaning.map((item, index) => {
                            return(
                                <li key={index}>
                                    <span>{item.name}</span>
                                    <span>{item.before ? item.before : ''} RM {item.price} / {item.unit}</span>
                                </li>
                            )
                        })}
                    </ul>  
                </div>
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                     
                     <h5><b>Ironing</b></h5>
                     <br />
                     <ul className="leaders">
                        {price_list_ironing.map((item, index) => {
                            return(
                                <li key={index}>
                                    <span>{item.name}</span>
                                    {/* <span>RM {item.price.toFixed(2)} / Pcs</span> */}
                                    <span>RM {item.price} / {item.unit}</span>
                                </li>
                            )
                        })}
                     </ul>
                     <small><b> RM 0.80 </b> off ironing (per pcs) when bundled with washing</small>
                 </div>
            </div>
        </div>  
        </div>
    </section>
    )
}